import Layout from '../components/layouts/en';
import LinkTree from '../components/LinkTree';
import React from 'react';

const HelloPage = ({ location, ...rest }) => (
  <Layout location={location}>
    <LinkTree />
  </Layout>
);

export default HelloPage;
