import { Link } from 'gatsby';
import Logo from '../../img/logo-hs.svg';
import React from 'react';
import styles from './index.module.sass';

export default class LinkTree extends React.Component {
  render() {
    return (
      <section className={styles.linkTree}>
        <div className={styles.bgImage} />
        <div className={styles.bgGradient} />
        <div className="container">
          <div className={styles.logo}>
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div className={styles.title}>
            TRACK WORKOUTS <br /> ANYWHERE
          </div>
          <div className={styles.subtitle}>Free &amp; Easy</div>
          <div className={styles.controls}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://itunes.apple.com/us/app/herosnatch/id1447158929?ls=1&mt=8"
              className="hs-button transparent"
            >
              Download iOS
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.herosnatch.app"
              className="hs-button transparent"
            >
              Download Android
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://herosnatch.com"
              className="hs-button"
            >
              Learn more
            </a>
          </div>
        </div>
      </section>
    );
  }
}

LinkTree.propTypes = {};
